






import Vue from 'vue'

export default Vue.extend({
  name: 'Address',
  mounted() {
    const urlParams = this.$route.params

    if (urlParams.postalCode) {
      this.$store.commit('address/updatePostcode', urlParams.postalCode)
    }
    if (urlParams.houseNumber) {
      this.$store.commit('address/updateHousenumber', urlParams.houseNumber)
    }
    if (urlParams.addition) {
      this.$store.commit('address/updateAddition', urlParams.addition)
    }

    this.$router.replace({ name: 'wizard' })
  }
})
